import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import makeStyles from '@mui/styles/makeStyles';
import SpendingSettings from '@true-link-financial/spending-settings-web-sdk';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

const useStyles = makeStyles({
  sdkContainer: {
    '& input[type="text"]': {
      border: '0',
      boxShadow: 'none',
      padding: '8.5px 14px 8.5px 0',
      margin: '0',
      height: '40px',
    },

    '& input[disabled]': {
      backgroundColor: '#fff',
    },
  },
  '@global': {
    'input#merchant_limit[type="number"], input#merchant_name[type="text"]': {
      height: '40px !important',
      marginBottom: '0 !important',
    },
    'input#merchant-cluster[type="text"]': {
      boxShadow: 'none',
      border: 'none',
      margin: '0',
    },
    '#merchant_info': {
      boxShadow: 'none',
    },
  },
});

const HuntingtonTheme = {
  fontFamily: 'Mulish',
  fontSize: '16px',
  textColor: '#394048',
  bannerColor: '#394048',
  maxWidth: '1280px',
  typography: {
    fontFamily: 'Mulish',
    h1: {
      fontFamily: '"Huntington Apex", sans-serif',
      fontSize: '24px',
      lineHeight: 1.2,
      letterSpacing: 0,
      color: '#2D822A',
    },
    h3: {
      fontSize: '16px',
      fontWeight: 600,
    },
    h4: {
      fontSize: '14px',
      fontWeight: 600,
      color: '#1C2025',
    },
    h6: {
      fontSize: '24px',
    },
    body1: {
      fontSize: '16px',
      lineHeight: 1.3,
    },
    body2: {
      fontSize: '14px',
    },
  },
  palette: {
    primary: {
      main: '#2D822A',
    },
    secondary: {
      main: '#394048',
    },
    tertiary: {
      main: '#2D822A',
    },
    darkText: {
      main: '#394048',
    },
    banner: {
      main: '#EFF1F3',
      contrast: '#394048',
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          '& .MuiAccordionSummary-root': {
            backgroundColor: '#fff',
            '&:hover': {
              backgroundColor: '#EFF1F3',
            },
            '&.Mui-expanded': {
              backgroundColor: '#EFF1F3',
            },
          },
          '& .MuiAccordionDetails-root': {
            backgroundColor: '#fff',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontWeight: '600',
          fontSize: '14px',
          borderRadius: '40px',
        },
        outlined: {
          border: '2px solid #394048',
          '&:hover': {
            border: '2px solid #2D822A',
            color: '#fff !important',
            background: '#2D822A',
            '& .MuiSvgIcon-root': {
              color: '#fff',
            },
          },
        },
        contained: {
          border: '2px solid #394048',
          '&:hover': {
            border: '2px solid #2D822A',
            color: '#fff',
            background: '#2D822A',
          },
        },
        iconSizeMedium: {
          display: 'none',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          display: 'block',
          fontWeight: '600',
          fontSize: '16px',
          color: '#394048',
          padding: '0 8px',
          background: 'white',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: '600',
          backgroundColor: '#E7EAEB',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          border: 'none',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& input[type=text]': {
            fontSize: 16,
          },
        },
      },
    },
  },
};

export default function SpendingSettingsSdkContainer({
  accountId,
  canEdit,
  ruleSetId,
  visibilityConfig,
}) {
  const classes = useStyles();

  const [checked, setChecked] = useState(true);
  const [showBudgetSettings, setShowBudgetSettings] = useState(false);
  const [showCurfewSettings, setShowCurfewSettings] = useState(false);
  const [readOnly, setReadOnly] = useState(!canEdit);

  useEffect(() => {
    const url = RailsRoutes.dashboard_account_find_organization_path({
      account_id: accountId,
      format: 'json',
    });

    axios.get(url).then((response) => {
      const meta = response?.data?.data.meta;
      const showBudget = meta.showBudgetSettings;
      setShowBudgetSettings(showBudget);
      const showCurfew = meta.showCurfewSettings;
      setShowCurfewSettings(showCurfew);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleReadOnlyChange = (event) => {
    setReadOnly(event.target.checked);
  };

  return (
    <div className={classes.sdkContainer}>
      <Grid alignItems="center" container direction="row" justifyContent="flex-end" sx={{ p: 2 }}>
        <div>True Link Theme</div>
        <Switch checked={checked} onChange={handleChange} />
        <div style={{ width: '140px' }}>Huntington Theme</div>
      </Grid>
      <Grid alignItems="center" container direction="row" justifyContent="flex-end" sx={{ p: 2 }}>
        <div>Admin View</div>
        <Switch checked={readOnly} onChange={handleReadOnlyChange} />
        <div style={{ width: '140px' }}>Cardholder View</div>
      </Grid>

      <SpendingSettings
        baseUrl={''}
        id={accountId}
        readOnly={readOnly}
        ruleSetId={ruleSetId}
        showBudgetSettings={showBudgetSettings}
        showCurfewSettings={showCurfewSettings}
        showMerchantSettings
        themeConfig={checked ? HuntingtonTheme : {}}
        visibilityConfig={visibilityConfig}
      />
    </div>
  );
}

SpendingSettingsSdkContainer.propTypes = {
  visibilityConfig: PropTypes.object,
  accountId: PropTypes.string,
  canEdit: PropTypes.bool,
  ruleSetId: PropTypes.string.isRequired,
};
